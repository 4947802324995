import React, { useContext, useEffect, useState } from "react";


import "./BannerPart3.css";
import axios from "axios";
import { baseUrl, dataContext, portal } from "../../App";
import { useNavigate } from "react-router-dom";
import activityLogSubmission from "../../helper/activitylog";
import RotatingBox from "./RotatingBox";
import analyticEvent from "../../helper/gaEvent";
import clevertap from "clevertap-web-sdk";
import BannerCategory from "./BannerCategory";
import CategoryBox from "./categoryBox/categoryBox";
const BannerPart3 = ({ props }) => {
  const navigate = useNavigate();
  const { token, setToken, playerData, setPlayerData, events, setEvents } =
    useContext(dataContext);
  const { showLoginModal, setShowLoginModal, showLoadingModal, setShowLoadingModal, showRedeemModal, setShowRedeemModal } = props;

  async function quizClickHandler(eventId) {
    console.log(playerData)
    if (!(playerData?.id)) {
      setShowLoginModal(true);
      // eventid 34 daily event
    } else if (eventId === 78) {
      if (playerData?.isSubscribe) {
        navigate(`/quiz/?eventId=${eventId}`, { replace: true });
      } else {
        if (playerData?.redeemed_code) {
          setShowLoadingModal(true)
          const config = {
            headers: { Authorization: `Token ${token}` },
          };
          axios
            .post(
              `${playerData?.subURL}`,
              { portal: portal, qcid: null, "service_type": "AppInApp" },
              config
            )
            .then((response) => {
              let data = response.data;
              setShowLoadingModal(false)
              // console.log(data);
              if (data?.redirectURL) {
                // analyticEvent('redirect', 'redirect-bkash-payment')
                console.log(`${data?.redirectURL}`);
                // window.location.replace(`${data?.redirectURL}`);
                window.location.replace(`${data?.redirectURL}`);
                // return data?.redirectURL
              }
            });
        } else {
          setShowRedeemModal(true)
        }
      }
    } else {

      if (playerData?.isSubscribeTournament) {
        navigate(`/quiz/?eventId=${eventId}`, { replace: true });
      } else {
        setShowLoadingModal(true)
        const config = {
          headers: { Authorization: `Token ${token}` },
        };
        axios
          .post(
            `${baseUrl}/subscription/create/`,
            { portal: portal, qcid: null, "service_type": "Tournament" },
            config
          )
          .then((response) => {
            let data = response.data;
            setShowLoadingModal(false)
            // console.log(data);
            if (data?.redirectURL) {
              // analyticEvent('redirect', 'redirect-bkash-payment')
              console.log(`${data?.redirectURL}`);
              // window.location.replace(`${data?.redirectURL}`);
              window.location.replace(`${data?.redirectURL}`);
              // return data?.redirectURL
            }
          });
      }
    }
  }
  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("home", msisdn);
    analyticEvent('home', 'home-view')
    clevertap.event.push("Product viewed");
  }, []);
  let userScore = [];
  userScore = events.map((event) => {
    let userLeaderboard = [];
    if (playerData?.id) {
      userLeaderboard = playerData?.leaderboard?.filter((singleEvent) => {
        if (singleEvent.event_id == event.id) {
          return singleEvent;
        }
      });
      return {
        eventTitle: event.events,
        score: userLeaderboard[0]?.score,
      };
    } else {
      return {
        eventTitle: event.events,
        score: "-",
      };
    }
  });
  // console.log(userScore);
  let userJsx = ``;
  for (let i = 0; i < userScore.length; i++) {
    if (i == 0) {
      userJsx = `<div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    } else {
      userJsx += `
    <div className="horizontalBar"></div>
    <div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    }
  }
  return (
    <>
      <div className="header-img-div">
        {/* <img src={playerCard} className="header-img" alt="" srcSet="" /> */}
        <div className="bannerAvatar-div">
          <img
            src={
              playerData?.avatar_img
                ? `${baseUrl}${playerData?.avatar_img}`
                : require(`../../assets/avatar/avatar${playerData?.avatar_id ? playerData.avatar_id : 1
                  }.png`)
            }
            className="banner-avatarImg"
            alt=""
            srcSet=""
          />
        </div>

        <div className="userEventScore">
          {/* {userJsx} */}
          {userScore.map((singleUserScore, index) => {
            if (index == 0) {
              return (
                <div className="singleEventScore" key={index}>
                  <div>{singleUserScore.eventTitle}</div>
                  <div>{singleUserScore.score}</div>
                </div>
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <div className="horizontalBar"></div>
                  <div className="singleEventScore">
                    <div>{singleUserScore.eventTitle}</div>
                    <div>{singleUserScore.score}</div>
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div>
      </div>
      <div className="bannerDiv">
        {/* <RotatingBox props={{ events,quizClickHandler }}></RotatingBox> */}
        <div style={{ color: '#4501a3', fontWeight: '600', fontSize: '2.2dvh', zIndex: 77 }}>কুইজ টুর্নামেন্ট</div>
        <BannerCategory propsN={{ events, quizClickHandler }}></BannerCategory>
        <div className="categoryQuizText">ক্যাটাগরি কুইজ</div>
        <CategoryBox props={{ events, quizClickHandler }} />
        {/* {events.map((event) => (
          <img
            key={event.id}
            className="singleBanner w-100"
            src={event.banner_url}
            onClick={() => quizClickHandler(event.id)}
          />
        ))} */}
      </div>
    </>
  );
};

export default BannerPart3;
