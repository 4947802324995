import React, { useContext, useRef } from 'react'
import styles from './Bottombar.module.css'
import homeImg from './images/home.png'
import leaderImg from './images/leaderboard.png'
import profileImg from './images/profile.png'
import winnerImg from './images/winner.png'
import { dataContext } from '../../App';

const Bottombar = ({ activeBottom, setActiveBottom, setShowLoginModal }) => {
  const { playerData } = useContext(dataContext);
  let imgSrc;
  if (activeBottom === 'home') { imgSrc = homeImg }
  if (activeBottom === 'leader') { imgSrc = leaderImg }
  if (activeBottom === 'profile') { imgSrc = profileImg }
  if (activeBottom === 'winner') { imgSrc = winnerImg }


  return (
    <div className={styles.ppm_bottom_bar}>
      <div className={styles.bottom_nav}>
        <img src={imgSrc} className={styles.bottomImg} alt="" srcSet="" />
        <div className={styles.leaderboardClick} onClick={() => { setActiveBottom('leader'); }}></div>
        <div className={styles.profileClick} onClick={() => { (playerData?.id) ? (setActiveBottom('profile')) : setShowLoginModal(true); }}></div>
        <div className={styles.homeClick} onClick={() => { setActiveBottom('home');}}></div>
        <div className={styles.winnerClick} onClick={() => { setActiveBottom('winner');}}></div>
      </div>
    </div>
  )
}

export default Bottombar