import React, { useState } from 'react'
// Import Swiper React components
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './BannerCategory.css'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30
  }
};
const BannerCategory = ({ propsN }) => {
  const { events, quizClickHandler } = propsN;
  const [isDragging, setIsDragging] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const eventControl = (event) => {
    // console.log(event)
    if (event.type === 'mousedown' || event.type === 'touchstart') {
      setIsMouseDown(true)
    }

    if (event.type === 'mousemove' || event.type === 'touchmove') {
      isMouseDown ? setIsDragging(true):setIsDragging(false);
    }

    if (event.type === 'mouseup' || event.type === 'touchend') {
      setIsMouseDown(false)
      setTimeout(() => {
        setIsDragging(false);
      }, 10);

    }
  }

  return (
    <>
      <Carousel responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        arrows={false}
        partialVisible={true}
        itemClass="carousel-item-padding-40-px"
        containerClass="carousel-container"
        additionalTransfrom={-10 * (events?.length || 0)}
        draggable={true}
        swipeable
      >
        {events.map((event, index) => (
          <img src={event.banner_url} alt="" srcSet="" style={{ width: '100%' }} draggable="false" onClick={() => {
            console.log('clicked', isDragging);
            if(!isDragging){quizClickHandler(event.id)}
          }}
            onMouseDown={(e) => eventControl(e)}
            onTouchStart={(e) => eventControl(e)}

            onMouseMove={(e) => { eventControl(e) }}
            onMouseUp={(e) => { eventControl(e) }}
            onTouchMove={(e) => { eventControl(e) }}
            onTouchEnd={(e) => { eventControl(e) }}
          />
        ))}
      </Carousel>
      <div className='dotBanner'><span>...</span></div>
    </>
  )
}

export default BannerCategory