import React, { useContext, useEffect, useState } from "react";
import "./Leaderboard.css";
import crown from "../../assets/img/v4/crown.png";
import { baseUrl, dataContext, portal } from "../../App";
import axios from "axios";
import activityLogSubmission from "../../helper/activitylog";
import analyticEvent from "../../helper/gaEvent";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { Observer } from "gsap/Observer";
import { useSwipeable } from "react-swipeable";
// const baseUrl = `https://quizcms.purplepatch.online`;

const Leaderboard = ({ showLoginModal, setShowLoginModal, activeBoard, setActiveBoard,toggleToUpdate,setToggleToUpdate }) => {
  const { token, setToken, playerData, setPlayerData, events, setEvents } =
    useContext(dataContext);
  // const [activeBoard, setActiveBoard] = useState(events[0]?.id);
  const [leaderBoardData, setLeaderBoardData] = useState([]);

  const [rankOneData, setRankOneData] = useState({});
  const [rankTwoData, setRankTwoData] = useState({});
  const [rankThreeData, setRankThreeData] = useState({});
  const handleImageError = (event) => {
    event.target.src = "/avatar1.png";
  };
  let config = {
    delta: 10,                             // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false,           // prevents scroll during swipe (*See Details*)
    trackTouch: true,                      // track touch input
    trackMouse: true,                     // track mouse input
    rotationAngle: 0,                      // set a rotation angle
    swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)
  }
  const handlers = useSwipeable({
    onSwiped: (eventData) => updateSwipeActiveBoard(eventData.deltaX),
    ...config,
  });
  useEffect(()=>{
    setToggleToUpdate(!toggleToUpdate)
  },[])
  useEffect(() => {
    if (events.length) {
      if (!activeBoard) setActiveBoard(events[0]?.id);
      axios
        .get(`${baseUrl}/api/leaderboard/?portal=${portal}&event_id=${activeBoard ? activeBoard : events[0]?.id}`)
        .then((response) => {
          let totalData = response.data;
          if (totalData.length > 3) {
            setRankOneData(totalData[0]);
            setRankTwoData(totalData[1]);
            setRankThreeData(totalData[2]);
            setLeaderBoardData(totalData.slice(3));
          } else {
            setLeaderBoardData([]);
            if (totalData.length === 0) {
              setRankOneData({});
              setRankTwoData({});
              setRankThreeData({});
            } else if (totalData.length === 1) {
              setRankOneData(totalData[0]);
              setRankTwoData({});
              setRankThreeData({});
            } else if (totalData.length === 2) {
              setRankOneData(totalData[0]);
              setRankTwoData(totalData[1]);
              setRankThreeData({});
            } else if (totalData.length === 3) {
              setRankOneData(totalData[0]);
              setRankTwoData(totalData[1]);
              setRankThreeData(totalData[2]);
            }
          }
          // console.log(response.data);
        });
    }
  }, [activeBoard]);

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("leaderboard", msisdn);
    analyticEvent('leaderboard', 'leaderboard-view')
  }, [])

  // playerLeaderboardData
  let userLeaderBoard = {};
  if (playerData?.id) {
    userLeaderBoard = {};
    let data = playerData.leaderboard.filter((value) => {
      if (value.event_id === activeBoard) return value;
    });
    userLeaderBoard = data[0];
  } else {
    userLeaderBoard = {};
  }
  // console.log(leaderBoardData)
  function updateSwipeActiveBoard(changeInX) {
    if(events?.length < 2){
      return;
    }
    let setPosition = null;
    events.forEach((event,index) => {
      if(event?.id === activeBoard) setPosition = index;
    });
    if(changeInX<5){
      if(setPosition>0){
      setActiveBoard(events[--setPosition]?.id)} 
    }
    if(changeInX > -5){
      if(setPosition < (events.length-1)){
      setActiveBoard(events[++setPosition]?.id)} 
    }
  }
  return (
    <>
      <div className="leaderBoardSelection" {...handlers}>
        {events.map((singleEvent) => {
          return (
            <div
              key={singleEvent.id}
              className={`leaderboardBtn ${activeBoard === singleEvent?.id ? "activeLeader" : ""
                }`}
              onClick={() => {
                setActiveBoard(singleEvent?.id);
              }}
            >
              {singleEvent?.events}
            </div>
          );
        })}
      </div>

      <div className="leaderBoardWrapper">
        <div className="hallOfFame" style={{ color: "#4501a3", fontSize: "2vh",fontWeight:'600' }}>
          <div className="insideBorder"></div>
          <div
            className={`rank2 ${rankTwoData?.User_Rank ? "" : "inactive"}`}
            style={{
              width: "30%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
            }}
          >
            <div
              className=""
              style={{
                width: "90%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <div className="">2nd&nbsp;</div>
              <img
                className="rankTwoImg"
                src={
                  rankTwoData?.avatar_img
                    ? `${baseUrl}/media/${rankTwoData?.avatar_img}`
                    : require(`../../assets/avatar/avatar${rankTwoData?.avatar_id ? rankTwoData.avatar_id : 1
                      }.png`)
                }
                alt=""
                onError={handleImageError}
                srcSet=""
              />
              <div className="">
                0
                {rankTwoData?.msisdn
                  ? (rankTwoData?.msisdn).toString().slice(0, 3)
                  : ""}
                ****
                {rankTwoData?.msisdn
                  ? (rankTwoData?.msisdn).toString().slice(-3)
                  : ""}
                &nbsp;
              </div>
              <div className="">Score: {rankTwoData?.score || "-"} &nbsp;</div>
            </div>
          </div>
          <div
            className={`rank1 ${rankOneData?.User_Rank ? "" : "inactive"}`}
            style={{
              width: "37%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
            }}
          >
            <div
              className=""
              style={{
                width: "92%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "end",
                position: "relative",
              }}
            >
              <img src={crown} className="crownImg" alt="" srcSet="" />
              <img
                src={
                  rankOneData?.avatar_img
                    ? `${baseUrl}/media/${rankOneData?.avatar_img}`
                    : require(`../../assets/avatar/avatar${rankOneData?.avatar_id ? rankOneData.avatar_id : 1
                      }.png`)
                }
                className="rankOneImg"
                onError={handleImageError}
                alt=""
                srcSet=""
              />
              <div className="">
                0
                {rankOneData?.msisdn
                  ? (rankOneData?.msisdn).toString().slice(0, 3)
                  : ""}
                ****
                {rankOneData?.msisdn
                  ? (rankOneData?.msisdn).toString().slice(-3)
                  : ""}
                &nbsp;
              </div>
              <div className="">Score: {rankOneData?.score || "-"} &nbsp;</div>
            </div>
          </div>
          <div
            className={`rank3 ${rankThreeData?.User_Rank ? "" : "inactive"}`}
            style={{
              width: "30%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
            }}
          >
            <div
              className=""
              style={{
                width: "90%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <div className="">&nbsp;3rd</div>
              <img
                src={
                  rankThreeData?.avatar_img
                    ? `${baseUrl}/media/${rankThreeData?.avatar_img}`
                    : require(`../../assets/avatar/avatar${rankThreeData?.avatar_id ? rankThreeData?.avatar_id : 1
                      }.png`)
                }
                className="rankThreeImg"
                alt=""
                onError={handleImageError}
                srcSet=""
              />
              <div className="">
                0
                {rankThreeData?.msisdn
                  ? (rankThreeData?.msisdn).toString().slice(0, 3)
                  : ""}
                ****
                {rankThreeData?.msisdn
                  ? (rankThreeData?.msisdn).toString().slice(-3)
                  : ""}
                &nbsp;
              </div>
              <div className="">
                Score: {rankThreeData?.score || "-"} &nbsp;
              </div>
            </div>
          </div>
        </div>

        <div className="rankTable mt-1" style={{ color: "white" }}>
          {leaderBoardData.map((singleData,index) => {
            return (
              <React.Fragment key={index}>
                <div className="perRow mt-3 mx-1" key={singleData.User_Rank}>
                  <div className="tdRank">{singleData.User_Rank}</div>
                  <div className="tdSmallAvatar">
                    <img
                      src={
                        singleData?.avatar_img
                          ? `${baseUrl}/media/${singleData?.avatar_img}`
                          : require(`../../assets/avatar/avatar${singleData.avatar_id ? singleData.avatar_id : 1
                            }.png`)
                      }
                      className="tdSmallAvatarImg"
                      onError={handleImageError}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <div className="tdMobileNumber">
                    0{(singleData?.msisdn).toString().slice(0, 3)}****
                    {(singleData?.msisdn).toString().slice(-3)}
                  </div>
                  <div className="tdTime">{singleData.time_taken}s</div>
                  <div className="tdScore">{singleData.score}</div>
                </div>
              </React.Fragment>
            );
          })}
        </div>

        <div className="userRow" style={{ color: "white" }}>
          {playerData?.id ? (
            <>
              <div className="perRow mt-2 mx-1">
                <div className="tdRank">
                  {userLeaderBoard?.round_number
                    ? `${userLeaderBoard.User_Rank}`
                    : "-"}
                </div>
                <div className="tdSmallAvatar">
                  <img
                    src={
                      playerData?.avatar_img
                        ? `${baseUrl}${playerData?.avatar_img}`
                        : require(`../../assets/avatar/avatar${playerData?.avatar_id ? playerData.avatar_id : 1
                          }.png`)
                    }
                    className="tdSmallAvatarImg"
                    alt=""
                    srcSet=""
                  />
                </div>
                <div className="tdMobileNumber">
                  0{playerData.msisdn.toString().slice(-10)}
                </div>
                <div className="tdTime">
                  {(playerData?.id) ? userLeaderBoard?.time_taken : "0"}s
                </div>
                <div className="tdScore"> {`${(userLeaderBoard?.score != undefined) ? userLeaderBoard?.score : '-'}`}</div>
              </div>
            </>
          ) : (
            <div className="perRow mt-2 mx-1">
              <div className="tdRank">-</div>
              <div className="tdSmallAvatar">
                <img
                  src={require(`../../assets/avatar/avatar1.png`)}
                  className="tdSmallAvatarImg"
                  alt=""
                  srcSet=""
                />
              </div>
              <div className="tdMobileNumber">***</div>
              <div className="tdTime">0s</div>
              <div className="tdScore"> - </div>
            </div>
          )}
        </div>
        {/* end wrapper */}
      </div>
    </>
  );
};

export default Leaderboard;
