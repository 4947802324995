import React, { useContext, useEffect, useState } from "react";
import "./HomePageV2.css";
import Navbar from "../../components/Navbar/Navbar";
import Bottombar from "../../components/Bottombar/Bottombar";
import Sidebar from "../../components/Sidebar/Sidebar";
import BannerPart2 from "../../components/BannerPart2/BannerPart2";
import LoginModal from "../../components/LoginModal/LoginModal";
import { dataContext, baseUrl, portal, firebaseAnalytics } from "../../App";
import Profile from "../../components/Profile/Profile";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import ContactModal from "../../components/Modal/ContactModal/ContactModal";
import TCModal from "../../components/Modal/TCModal/TCModal";
import ReferralPolicyModal from "../../components/Modal/ReferralPolicyModal/ReferralPolicyModal";
import RefundModal from "../../components/Modal/RefundModal/RefundModal";
import RewardsModal from "../../components/Modal/RewardsModal/RewardsModal";

// import homeImg from "../../assets/img/bg_min_v2.png";
import homeImg from "../../assets/img/background/bg.png";
import leaderboardImg from "../../assets/img/v4/BG1.png";
import activityLogSubmission from "../../helper/activitylog";
import analyticEvent from "../../helper/gaEvent";
import LoadingModal from "../../components/Modal/LoadingModal/LoadingModal";
import SubscriptionModal from "../../components/Modal/SubscriptionModal/SubscriptonModal";
import UnSubscriptionModal from "../../components/Modal/UnSubscriptionModal/UnSubscriptionModal";
import ErrorSubscriptionModal from "../../components/Modal/ErrorSubscriptionModal/ErrorSubscriptionModal";
import RedeemModal from "../../components/Modal/RedeemModal/RedeemModal";
import LeaderWinnerList from "../../components/LeaderWinnerList/LeaderWinnerList";
const HomePageV2 = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const bottomBarPosition = searchParams.get("page") || "home";
  const { token, setToken, playerData, setPlayerData, events } = useContext(dataContext);
  const [activeBottom, setActiveBottom] = useState(bottomBarPosition);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showTCModal, setShowTCModal] = useState(false);
  const [showReferralPolicyModal, setShowReferralPolicyModal] = useState(false);
  const [showRewardsModal, setShowRewardsModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showErrorSubscriptionModal, setShowErrorSubscriptionModal] = useState(false);
  const [showUnSubscriptionModal, setShowUnSubscriptionModal] = useState(false);
  const [toggleToUpdate, setToggleToUpdate] = useState(false);
  const [activeBoard, setActiveBoard] = useState(events[0]?.id);
  const [activeLeaderPage, setActiveLeaderPage] = useState('singleLeaderPage');
  let checkFailedSubscription = (searchParams.get("status") === 'CANCELLED' || searchParams.get("status") === 'FAILED')
  let checkSuccessfulSubscription = (searchParams.get("status") === 'SUCCEEDED')
  // console.log(searchParams)
  let cid = sessionStorage.getItem('cid');
  useEffect(() => {
    searchParams.delete('page')
    setSearchParams(searchParams)
    // console.log(searchParams)
    if (searchParams.get('qcid')) { sessionStorage.setItem('cid', searchParams.get('qcid')); cid = searchParams.get('qcid') }
  }, [bottomBarPosition]);
  function callFloodlightTag() {
    console.log('calling floodlight tag')
    // Floodlight tag URL
    var floodlightTagUrl = "https://ad.doubleclick.net/ddm/activity/src=14037245;type=sales;cat=quiza0;qty=1;cost=[Revenue];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=[OrderID]?";
    
    // Make a request to the Floodlight tag URL using an XMLHttpRequest
    var xhr = new XMLHttpRequest();
    xhr.open("GET", floodlightTagUrl, true);
    xhr.send();
}

  useEffect(() => {
    if (checkSuccessfulSubscription) {
      setShowSubscriptionModal(true)
    }
    if (checkFailedSubscription) {
      setShowErrorSubscriptionModal(true)
    }
    if (!token) {
      setPlayerData({});
      let msisdn = null;
      activityLogSubmission("landing", null);
      analyticEvent('home', 'home-view')

    } else {
      const config = {
        headers: { Authorization: `Token ${token}` },
      };

      axios
        .get(
          `${baseUrl}/api/clients/participant_profiles/?portal=${portal}${(checkSuccessfulSubscription) ? `&status=SUCCEEDED&reference=${searchParams.get("reference")}` : ''}`,
          config
        )
        .then((response) => {
          if (checkSuccessfulSubscription) {
            analyticEvent('bkash-subscription', 'bkash-subscription-success')
            activityLogSubmission("subscription_success", response.data[0]?.msisdn);
            if(sessionStorage.getItem('cid')==19){
              callFloodlightTag()
              firebaseAnalytics.logEvent('begin_checkout', {
                reference: searchParams.get("reference") || ""
            });
          }
          }
          if (checkFailedSubscription) {
            analyticEvent('bkash-subscription', 'bkash-subscription-failed')
            activityLogSubmission("subscription_failed", response.data[0]?.msisdn);
          }
          searchParams.delete('status')
          setSearchParams(searchParams)
          setPlayerData(response.data[0]);
          // console.log(response.data[0]);
          activityLogSubmission("landing", response.data[0]?.msisdn);
          let data = response.data[0];
          // console.log(data);

          if (!data?.isSubscribe && (!checkFailedSubscription)) {
            // axios
            //   .post(
            //     `${baseUrl}/subscription/create/`,
            //     { portal: portal,cid: cid},
            //     config
            //   )
            //   .then((response) => {
            //     let data = response.data;
            //     // console.log(data);
            //     if (data?.redirectURL) {
            //       analyticEvent('redirect', 'redirect-bkash-payment')
            //       console.log(`${data?.redirectURL}`);
            //       // window.location.replace(`${data?.redirectURL}`);
            //     }
            //   });

          }
        })
        .catch(res => {
          // console.log(res.response.status === 401)
          sessionStorage.setItem("AuthToken", "");
          sessionStorage.setItem("user_msisdn", "");
          setPlayerData({});
          // setShowLoginModal(true);
          setToken(null);
        });
    }
  }, [token, activeBoard, toggleToUpdate]);
  let backgroundImg;
  activeBottom === "leader"
    ? (backgroundImg = homeImg)
    : (backgroundImg = homeImg);
  // const [showProfile, setShowProfile] = useState(false);
  // (playerData?.id)? setShowProfile(true):setShowProfile(false)
  return (
    <div
      className="homePageBody"
      style={{ backgroundImage: `url(${backgroundImg})` }}
    >
      <Sidebar
        props={{
          showSidebar,
          setShowSidebar,
          setShowLoginModal,
          showLoginModal,
          activeBottom,
          setActiveBottom,
          showContactModal,
          setShowContactModal,
          showTCModal,
          setShowTCModal,
          showReferralPolicyModal,
          setShowReferralPolicyModal,
          showRefundModal,
          setShowRefundModal,
          showRewardsModal,
          setShowRewardsModal,
        }}
      ></Sidebar>
      <Navbar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      ></Navbar>
      {activeBottom === "home" && (
        <BannerPart2
          props={{ setShowLoginModal, showLoginModal, setShowLoadingModal, showLoadingModal,showRedeemModal,setShowRedeemModal }}
        ></BannerPart2>
      )}
      {(activeBottom === "winner") && (
        <LeaderWinnerList
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
          activeBoard={activeBoard} setActiveBoard={setActiveBoard} setToggleToUpdate={setToggleToUpdate} toggleToUpdate={toggleToUpdate}
        ></LeaderWinnerList>
      )}
      {(activeBottom === "leader") && (
        <Leaderboard
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
          activeBoard={activeBoard} setActiveBoard={setActiveBoard} setToggleToUpdate={setToggleToUpdate} toggleToUpdate={toggleToUpdate}
        ></Leaderboard>
      )}
      {activeBottom === "profile" && (
        <Profile
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
        ></Profile>
      )}
      <Bottombar
        activeBottom={activeBottom}
        setActiveBottom={setActiveBottom}
        setShowLoginModal={setShowLoginModal}
        activeLeaderPage = {activeLeaderPage}
        setActiveLeaderPage ={setActiveLeaderPage}
      ></Bottombar>
      {showLoginModal && (
        <LoginModal
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
          activeBottom={activeBottom}
          setActiveBottom={setActiveBottom}
        ></LoginModal>
      )}
      {showLoadingModal && (
        <LoadingModal
          showLoadingModal={showLoadingModal}
          setShowLoadingModal={setShowLoadingModal}
        ></LoadingModal>
      )}
      {showRedeemModal && (
        <RedeemModal
          showRedeemModal={showRedeemModal}
          setShowRedeemModal={setShowRedeemModal}
          setShowLoadingModal={setShowLoadingModal}
          setToggleToUpdate={setToggleToUpdate} toggleToUpdate={toggleToUpdate}
        ></RedeemModal>
      )}
      {showSubscriptionModal && (
        <SubscriptionModal
          showSubscriptionModal={showSubscriptionModal}
          setShowSubscriptionModal={setShowSubscriptionModal}
        ></SubscriptionModal>
      )}
      {showErrorSubscriptionModal && (
        <ErrorSubscriptionModal
          showErrorSubscriptionModal={showErrorSubscriptionModal}
          setShowErrorSubscriptionModal={setShowErrorSubscriptionModal}
        ></ErrorSubscriptionModal>
      )}
      {showUnSubscriptionModal && (
        <UnSubscriptionModal
          showUnSubscriptionModal={showUnSubscriptionModal}
          setShowUnSubscriptionModal={setShowUnSubscriptionModal}
        ></UnSubscriptionModal>
      )}
      {showContactModal && (
        <ContactModal
          showContactModal={showContactModal}
          setShowContactModal={setShowContactModal}
        ></ContactModal>
      )}
      {showTCModal && (
        <TCModal
          showTCModal={showTCModal}
          setShowTCModal={setShowTCModal}
        ></TCModal>
      )}
      {showReferralPolicyModal && (
        <ReferralPolicyModal
          showReferralPolicyModal={showReferralPolicyModal}
          setShowReferralPolicyModal={setShowReferralPolicyModal}
        ></ReferralPolicyModal>
      )}
      {showRewardsModal && (
        <RewardsModal
          showRewardsModal={showRewardsModal}
          setShowRewardsModal={setShowRewardsModal}
        ></RewardsModal>
      )}
      {showRefundModal && (
        <RefundModal
          showRefundModal={showRefundModal}
          setShowRefundModal={setShowRefundModal}
          setToggleToUpdate={setToggleToUpdate} toggleToUpdate={toggleToUpdate} setShowUnSubscriptionModal={setShowUnSubscriptionModal}
        ></RefundModal>
      )}
    </div>
  );
};

export default HomePageV2;
