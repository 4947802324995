import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import reward1 from "../../assets/rewards/1.png";
import reward2 from "../../assets/rewards/2.png";
import reward3 from "../../assets/rewards/3.png";
// Import Swiper styles
import "swiper/css";
// import 'swiper/css/navigation';
import "swiper/css/pagination";
import "swiper/css/effect-cube";
import { Navigation } from "swiper/modules";
import {
  Autoplay,
  EffectCoverflow,
  Pagination,
  EffectCube,
} from "swiper/modules";
import "./BannerPart2.css";
import axios from "axios";
import playerCard from "../../assets/img/v2/playerCardPrev_white.png";
import playerCard1 from "../../assets/avatar/avatar1.png";
import { baseUrl, dataContext, portal } from "../../App";
import { useNavigate } from "react-router-dom";
// import JsCss from './JsCss/JsCss';
import ReactSwipeable from "./ReactSwipeable/ReactSwipeable";
import activityLogSubmission from "../../helper/activitylog";
const BannerPart2 = ({ props }) => {
  const navigate = useNavigate();
  const { token, setToken, playerData, setPlayerData, events, setEvents } =
    useContext(dataContext);
  const { showLoginModal, setShowLoginModal,showLoadingModal, setShowLoadingModal,showRedeemModal,setShowRedeemModal } = props;
  // console.log(token)
  // console.log(playerData)

  // console.log(events)
  async function quizClickHandler(eventId) {
  console.log(playerData)
    if (!(playerData?.id)) {
      setShowLoginModal(true);
    } else if (playerData?.isSubscribe) {
      navigate(`/quiz/?eventId=${eventId}`, { replace: true });
    }else{
      if(playerData?.redeemed_code){
        setShowLoadingModal(true)
        const config = {
          headers: { Authorization: `Token ${token}` },
        };
        axios
          .post(
            `${baseUrl}/subscription/create/`,
            { portal: portal, qcid: null },
            config
          )
          .then((response) => {
            let data = response.data;
            setShowLoadingModal(false)
            // console.log(data);
            if (data?.redirectURL) {
              // analyticEvent('redirect', 'redirect-bkash-payment')
              console.log(`${data?.redirectURL}`);
              // window.location.replace(`${data?.redirectURL}`);
              window.location.replace(`${data?.redirectURL}`);
              // return data?.redirectURL
            }
          });
      }else{
        setShowRedeemModal(true)
      }
    }
  }
  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("home", msisdn);
  }, []);
  let userScore = [];
  userScore = events.map((event) => {
    let userLeaderboard = [];
    if (playerData?.id) {
      userLeaderboard = playerData?.leaderboard?.filter((singleEvent) => {
        if (singleEvent.event_id == event.id) {
          return singleEvent;
        }
      });
      return {
        eventTitle: event.events,
        score: userLeaderboard[0]?.score,
      };
    } else {
      return {
        eventTitle: event.events,
        score: "-",
      };
    }
  });
  // console.log(userScore);
  let userJsx = ``;
  for (let i = 0; i < userScore.length; i++) {
    if (i == 0) {
      userJsx = `<div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    } else {
      userJsx += `
    <div className="horizontalBar"></div>
    <div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    }
  }
  return (
    <>
      <div className="header-img-div">
        {/* <img src={playerCard} className="header-img" alt="" srcSet="" /> */}
        <div className="bannerAvatar-div">
          <img
            src={
              playerData?.avatar_img
                ? `${baseUrl}${playerData?.avatar_img}`
                : require(`../../assets/avatar/avatar${playerData?.avatar_id ? playerData.avatar_id : 1
                  }.png`)
            }
            className="banner-avatarImg"
            alt=""
            srcSet=""
          />
        </div>

        <div className="userEventScore">
          {/* {userJsx} */}
          {userScore.map((singleUserScore, index) => {
            if (index == 0) {
              return (
                <div className="singleEventScore" key={index}>
                  <div>{singleUserScore.eventTitle}</div>
                  <div>{singleUserScore.score}</div>
                </div>
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <div className="horizontalBar"></div>
                  <div className="singleEventScore">
                    <div>{singleUserScore.eventTitle}</div>
                    <div>{singleUserScore.score}</div>
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div>
      </div>
      <div className="bannerDiv">
        {events.map((event) => (
          <img
            key={event.id}
            className="singleBanner w-100"
            src={event.banner_url}
            onClick={() => quizClickHandler(event.id)}
          />
        ))}
      </div>
    </>
  );
};

export default BannerPart2;
