// import logo from './logo.svg';
import "./App.css";
import { createBrowserRouter, RouterProvider, useSearchParams } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import HomePageV2 from "./pages/HomePageV2/HomePageV2";
import { createContext, useEffect, useState } from "react";
import axios from "axios";
import QuizPage from "./pages/QuizPage/QuizPage";
import activityLogSubmission from "./helper/activitylog";
import Campaign from "./pages/Campaign/Campaign";
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import HomePageV3 from "./pages/HomePageV3/HomePageV3";
import Campaign2 from "./pages/Campaign2/Campaign2";
export const baseUrl = `https://cms.quizard.live`;
export const portal = 20;
export const dataContext = createContext();
const firebaseConfig = {
  apiKey: "AIzaSyBLB8mg0DfRjP4QOx-nS6TRRhtlK0VV29s",
  authDomain: "quizard-cpa.firebaseapp.com",
  projectId: "quizard-cpa",
  storageBucket: "quizard-cpa.appspot.com",
  messagingSenderId: "305352260036",
  appId: "1:305352260036:web:c8dce84b75b6d09268cc91",
  measurementId: "G-VWCLVZC2QB"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(app);

const router = createBrowserRouter([
  {
    path: "/rolling",
    element: <HomePageV3 />,
  },
  {
    path: "/base",
    element: <HomePageV2 />,
  },
  {
    path: "/",
    element: <HomePageV3 />,
  },
  {
    path: "/campaign/",
    element: <Campaign />,
  },
  {
    path: "/campaign2/",
    element: <Campaign2 />,
  },
  {
    path: "/quiz/",
    element: <QuizPage></QuizPage>,
  },
]);
function App() {
  const url = new URL(window.location.href);
  let urlTokenParam = url.searchParams.get("auth_token_url");
  url.searchParams.delete('auth_token_url');
  window.history.pushState({}, '', url.href);
  let localToken = sessionStorage.getItem("AuthToken") || urlTokenParam || "";

  const [token, setToken] = useState(localToken);
  const [playerData, setPlayerData] = useState({});
  const [events, setEvents] = useState([]);
  useEffect(() => {
    axios.get(`${baseUrl}/api/rules/?portal=${portal}`).then((response) => {
      setEvents(response.data);
    });
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    document.addEventListener('keydown', event => {
      if ((event.ctrlKey || event.metaKey) && (event.shiftKey) && (event.key === 'I' || event.key === 'J') || event.keyCode === 123) {
        event.preventDefault();
      }
    });
    activityLogSubmission("landing", sessionStorage.getItem('user_msisdn') || null);
  }, []);

  ReactGA.initialize([
    {
      trackingId: "G-CRSTC3TC4D",
    },
    {
      trackingId: "G-4PB7ZL0RMX",
    },
  ]);
  return (
    <div className="App">
      <div className="ppm_quiz_body">
        <dataContext.Provider
          value={{
            token,
            setToken,
            playerData,
            setPlayerData,
            events,
            setEvents,
            portal,
          }}
        >
          <RouterProvider router={router} />
        </dataContext.Provider>
        <button
  id="bkash_btn"
  type="button"
  onClick={() => window.webViewJSBridge.goBackHome('Tickets')}
  style={{
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '50px',
    fontSize: '18px',
    borderRadius: '0px',
    marginTop: '0%',
    color: 'white',
    textAlign: 'left',
    backgroundColor: '#E2136E',
    borderColor: '#E2136E',
  }}
>
  Back to bKash App Home
  <img
    src="https://capp-cdn.labs.bka.sh/images/arrow.svg"
    style={{
      float: 'right',
      marginTop: '1%',
      paddingRight: '1%',
    }}
    alt="arrow"
  />
</button>

      </div>
    </div>
  );
}

export default App;
