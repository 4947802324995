import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dataContext } from "../../App";
import landingImg from "../../assets/landingPage/landingPage.jpg";
import newLandingImg from "./assets/campLanding.jpg";
import LoginModalCampaign from "../../components/LoginModalCampaign/LoginModalCampaign";
import activityLogSubmission from "../../helper/activitylog";
import styles from "./Campaign.module.css";
const Campaign = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeBottom, setActiveBottom] = useState("campaign");
  const [showLoginModalCampaign, setShowLoginModalCampaign] = useState(false);
  const cid = searchParams.get("qcid");
  const {playerData} = useContext(dataContext);
  let navigate = useNavigate();
  if (!cid) {
    navigate("../");
  }
  useEffect(() => {
    let campaignId = null;
    if(searchParams.get('qcid')){
      sessionStorage.setItem('cid', searchParams.get('qcid'));
      campaignId = searchParams.get('qcid');
    }

    setTimeout(() => {
      if(campaignId !== '19'){setShowLoginModalCampaign(true);}
      // just checking commit 
    }, 2000);
    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("campaign", msisdn);
  }, []);

  return (
    <>
      <div className={`${styles.landingPage}`}>
        <img src={newLandingImg} alt="" srcSet="" className={styles.landingImg} onClick={()=>{
          setShowLoginModalCampaign(true)
        }} />
        <LoginModalCampaign
          showLoginModalCampaign={showLoginModalCampaign}
          setShowLoginModalCampaign={setShowLoginModalCampaign}
          activeBottom={activeBottom}
          setActiveBottom={setActiveBottom}
        ></LoginModalCampaign>
      </div>
    </>
  );
};

export default Campaign;
